import PropTypes from "prop-types";
import {createDescriptionObjectForList, createListFromObject} from "../components/common/commonUtilities";

const inputTheme = `input-theme`;
const checkboxTheme = `${inputTheme}__checkbox`;
const radioTheme = `${inputTheme}__radio`;

export const fileUploadTypes = {
	EXCEL: `EXCEL`
}

export const otherTextSeparator = " - ";
export const inputStyles = {
	DISABLED: `is-disabled`,
	ERROR: `has-error`,
	HIDDEN: `is-visuallyhidden`,
	THEME: inputTheme,
	checkbox: {
		THEME: checkboxTheme,
		direction: {
			HORIZONTAL: `${checkboxTheme}--horizontal`,
			VERTICAL: ``
		}
	},

	radio: {
		THEME: radioTheme,
		direction: {
			HORIZONTAL: `${radioTheme}--horizontal`,
			VERTICAL: ``
		},
		withClear: {
			BUTTON: `button--clear-response-for-radio-button-list`,
			LEGEND: `shrink-legend`,
			WRAPPER: `options-with-clear-response`
		}
	},

	columnRadio: {
		THEME: `${radioTheme}--column`
	},

	radioButtons:{
		group: {
			APPROVALS: `button-approvals`
		}
	},

	textbox: {
		OTHER: `other-text`
	}
};

export const gridConstants = {
	column: {
		ONE: 1,
		TWO: 2,
		THREE: 3,
		FOUR: 4,
		FIVE: 5,
		SIX: 6,
		SEVEN: 7,
		EIGHT: 8,
		NINE: 9,
		TEN: 10,
		ELEVEN: 11,
		TWELVE: 12,
		AUTO: `auto`,
		SHRINK: `shrink`,
		PROP_TYPE: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
		]),
	}
};

export const DateTimePickerType = {
	Calendar: 0,
	CalendarWithTime: 1,
	TimeOnly: 2
};

export const dateSelectOptionChoices = {
	previous: "previous",
	future: "future",
	upcoming: "upcoming",
	quarters: "quarters",
	quartersWithCustom: "quartersWithCustom"
}

export const previousDateSelectFieldOptions = {
	ThisWeek: "ThisWeek",
	LastWeek: "LastWeek",
	ThisMonth: "ThisMonth",
	LastThreeMonths: "LastThreeMonths",
	Custom: "Custom"
}

export const previousDateSelectFieldOptionsList = createListFromObject(previousDateSelectFieldOptions);

export const futureDateSelectFieldOptions = {
	Overdue: "Overdue",
	ThisWeek: "ThisWeek",
	NextWeek: "NextWeek",
	ThisMonth: "ThisMonth",
	NextThreeMonths: "NextThreeMonths",
	Custom: "Custom"
}

export const futureDateSelectFieldOptionsList = createListFromObject(futureDateSelectFieldOptions);
export const upcomingDateSelectFieldOptionsList = createListFromObject(futureDateSelectFieldOptions).filter(f => f.value !== futureDateSelectFieldOptions.Overdue);

export const quartersDateSelectFieldOptions = {
	quarter1: createDescriptionObjectForList("Quarter 1", "Quarter 1"),
	quarter2: createDescriptionObjectForList("Quarter 2", "Quarter 2"),
	quarter3: createDescriptionObjectForList("Quarter 3", "Quarter 3"),
	quarter4: createDescriptionObjectForList("Quarter 4", "Quarter 4"),
	yearToDate: createDescriptionObjectForList("Year-To-Date", "Year-To-Date")
}

export const quartersWithCustomDateSelectFieldOptions = {
	...quartersDateSelectFieldOptions,
	Custom: "Custom"
}

export const quartersDateSelectFieldOptionsList = createListFromObject(quartersDateSelectFieldOptions);
export const quartersWithCustomDateSelectFieldOptionsList = createListFromObject(quartersWithCustomDateSelectFieldOptions);

export const actionTypes = {
	REMOVE: "remove",
	ADD: "add"
};